<template>
  <div v-loading="loading">
    <el-card shadow="never">
      <div slot="header">
        <span class="iconfont icon-HomeTheme">&nbsp;桌面主题</span>
      </div>
      <div class="card_header_flex">
        <el-button
          type="primary"
          size="small"
          @click="dialogAddHomeTheme=true"
          v-if="userInfo.userLevel=='super_admin'"
        >新增主题</el-button>
      </div>
      <el-row :gutter="10">
        <el-col
          :xl="6"
          :lg="8"
          :md="12"
          :sm="24"
          :xs="24"
          :offset="0"
          v-for="theme in list"
          :key="theme.id"
        >
          <div class="theme_selected_card">
            <el-carousel indicator-position="none" height="200px" style="width:350px">
              <el-carousel-item v-for="(item,index) in theme.imgList" :key="index">
                <el-image :src="fileUrl+item" fit="fill" style="border-radius: 4px"></el-image>
              </el-carousel-item>
            </el-carousel>

            <div class="card_bottom_menu">
              <div>
                <p>{{theme.title}}</p>
                <p style="font-size:12px;color:#909399;line-height:30px">{{theme.content}}</p>
              </div>
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  @click="openEditHomeTheme(theme)"
                  v-if="userInfo.userLevel=='super_admin'"
                >编 辑</el-button>
                <el-button
                  type="primary"
                  size="mini"
                  v-if="theme.tag !=placesInfo.tag"
                  @click="applicationHomeTheme(theme)"
                >应 用</el-button>
                <el-button v-else size="mini" disabled>已应用</el-button>
              </div>
            </div>

            <label
              class="theme_selected_card_del"
              v-if="userInfo.userLevel=='super_admin'"
              @click="onSubmitDelTheme(theme)"
            >
              <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF"></i>
            </label>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <!-- 新增主题 -->
    <el-dialog
      :visible.sync="dialogAddHomeTheme"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogAddHomeTheme"
    >
      <span slot="title">新增主题</span>
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="标题"
          prop="title"
          :rules="{ required: true, message: '请输入主题标题', trigger: 'blur' }"
        >
          <el-input v-model="form.title" placeholder="请输入主题标题"></el-input>
        </el-form-item>
        <el-form-item
          label="描述"
          prop="content"
          :rules="{ required: true, message: '请输入主题描述', trigger: 'blur' }"
        >
          <el-input v-model="form.content" placeholder="请输入主题描述"></el-input>
        </el-form-item>
        <el-form-item
          label="标识"
          prop="tag"
          :rules="{ required: true, message: '请输入主题标识', trigger: 'blur' }"
        >
          <el-input v-model="form.tag" placeholder="请输入主题标识"></el-input>
        </el-form-item>

        <el-form-item label="主题展示图">
          <el-row :gutter="0">
            <el-col
              :xl="4"
              :lg="6"
              :md="8"
              :sm="12"
              :xs="24"
              :offset="0"
              v-for="(item,index) in form.imgList"
              :key="index"
              style="margin-bottom:10px;"
            >
              <div class="theme_img_card">
                <el-image style="width: 100px; height: 100px" :src="fileUrl+item" fit="contain"></el-image>
                <label @click="delAddImgs(index)" class="delImgIcon">
                  <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                </label>
              </div>
            </el-col>
            <el-col
              :xl="4"
              :lg="6"
              :md="8"
              :sm="12"
              :xs="24"
              :offset="0"
              style="margin-bottom:10px;"
              v-if="form.imgList.length<9"
            >
              <el-dropdown placement="top">
                <div class="theme_addImg_card">
                  <i class="el-icon-plus theme_addImg_card_icon"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="dialogAddImgFileUpload=true"
                    style="padding:0"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                  <el-dropdown-item style="padding:0">
                    <el-upload
                      style="text-align:center"
                      :action="actionUrl"
                      accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                      :headers="header"
                      :data="{type:0,category:'图片'}"
                      :show-file-list="false"
                      :on-success="handleAddSuccess"
                      :before-upload="beforeAddUpload"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddHomeTheme=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddHomeTheme">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑主题 -->
    <el-dialog
      :visible.sync="dialogEditHomeTheme"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogEditHomeTheme"
    >
      <span slot="title">编辑主题</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="100px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="标题"
          prop="title"
          :rules="{ required: true, message: '请输入主题标题', trigger: 'blur' }"
        >
          <el-input v-model="editForm.title" placeholder="请输入主题标题"></el-input>
        </el-form-item>
        <el-form-item
          label="描述"
          prop="content"
          :rules="{ required: true, message: '请输入主题描述', trigger: 'blur' }"
        >
          <el-input v-model="editForm.content" placeholder="请输入主题描述"></el-input>
        </el-form-item>
        <el-form-item
          label="标识"
          prop="tag"
          :rules="{ required: true, message: '请输入主题标识', trigger: 'blur' }"
        >
          <el-input v-model="editForm.tag" placeholder="请输入主题标识"></el-input>
        </el-form-item>

        <el-form-item label="主题展示图">
          <el-row :gutter="0">
            <el-col
              :xl="4"
              :lg="6"
              :md="8"
              :sm="12"
              :xs="24"
              :offset="0"
              v-for="(item,index) in editForm.imgList"
              :key="index"
              style="margin-bottom:10px;"
            >
              <div class="theme_img_card">
                <el-image style="width: 100px; height: 100px" :src="fileUrl+item" fit="contain"></el-image>
                <label @click="delEditImgs(index)" class="delImgIcon">
                  <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                </label>
              </div>
            </el-col>
            <el-col
              :xl="4"
              :lg="6"
              :md="8"
              :sm="12"
              :xs="24"
              :offset="0"
              style="margin-bottom:10px;"
              v-if="form.imgList.length<9"
            >
              <el-dropdown placement="top">
                <div class="theme_addImg_card">
                  <i class="el-icon-plus theme_addImg_card_icon"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="dialogEditImgFileUpload=true"
                    style="padding:0"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                  <el-dropdown-item style="padding:0">
                    <el-upload
                      style="text-align:center"
                      :action="actionUrl"
                      accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                      :headers="header"
                      :data="{type:0,category:'图片'}"
                      :show-file-list="false"
                      :on-success="handleEditSuccess"
                      :before-upload="beforeEditUpload"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditHomeTheme=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditHomeTheme">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加图片窗口 -->
    <el-dialog
      :visible.sync="dialogAddImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload
        :choice="false"
        :type="0"
        :count="this.isMaxImgCount"
        :category="'图片'"
        v-if="dialogAddImgFileUpload"
      ></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogAddImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitAddImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改图片窗口 -->
    <el-dialog
      :visible.sync="dialogEditImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload
        :choice="false"
        :type="0"
        :count="this.isMaxImgCount"
        :category="'图片'"
        v-if="dialogEditImgFileUpload"
      ></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditImg" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getHomeThemeApi,
  editHomeThemeApi,
  addHomeThemeApi,
  delHomeThemeApi,
  applicationHomeThemeApi
} from "../../api/data";
import FileUpload from "../../components/FileUpload";
import Aes from "../../utils/aes";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      loading: true,
      list: [],
      form: {
        title: "",
        content: "",
        imgList: [],
        tag: ""
      },
      editForm: {},
      dialogAddHomeTheme: false, //添加主题窗口
      dialogAddImgFileUpload: false, //添加主题图片窗口
      dialogEditHomeTheme: false, //添加主题窗口
      dialogEditImgFileUpload: false //添加主题图片窗口
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    //登录账户信息
    userInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    },
    //最大上传图片数
    isMaxImgCount() {
      return 9 - this.form.imgList.length;
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getHomeThemeApi({ groupId: this.placesInfo.groupId }).then(res => {
        this.list = res.data;
        this.loading = false;
      });
    },
    // 应用主题
    applicationHomeTheme(obj) {
      applicationHomeThemeApi({
        tag: obj.tag,
        groupId: this.placesInfo.groupId
      }).then(res => {
        if (res) {
          this.$store.commit(
            "setPlacesInfo",
            Aes.encrypt(JSON.stringify(res.data))
          );
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //添加主题
    onSubmitAddHomeTheme() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          addHomeThemeApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogAddHomeTheme = false;
              this.initLoad();
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加主题关闭窗口事件
    closeDialogAddHomeTheme() {
      this.$refs["form"].resetFields();
    },
    //打开修改主题窗口
    openEditHomeTheme(obj) {
      this.dialogEditHomeTheme = true;
      this.editForm = { ...obj };
    },
    //提交修改主题
    onSubmitEditHomeTheme() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          editHomeThemeApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogEditHomeTheme = false;
              this.initLoad();
            }
          });
        } else {
          return false;
        }
      });
    },
    //修改主题关闭窗口事件
    closeDialogEditHomeTheme() {
      this.$refs["editForm"].resetFields();
    },
    //删除主题
    onSubmitDelTheme(obj) {
      this.$confirm("此操作将永久删除该主题, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delHomeThemeApi({ id: obj.id }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },

    //上传图片完成事件
    handleAddSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.imgList.push(result.msg);
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传图片前事件
    beforeAddUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库图片添加完成事件
    onSubmitAddImg() {
      this.dialogAddImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.form.imgList.push(this.$store.state.fileList[i].filePath);
        }
        this.$store.commit("setFileList", []);
      }
    },

    //删除添加图片集合
    delAddImgs(index) {
      this.form.imgList.splice(index, 1);
    },
    //图片窗口关闭事件
    closeDialogImgFileUpload() {
      this.$store.commit("setFileList", []);
    },

    //修改
    //上传图片完成事件
    handleEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.editForm.imgList.push(result.msg);
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传图片前事件
    beforeEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库图片添加完成事件
    onSubmitEditImg() {
      this.dialogEditImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.editForm.imgList.push(this.$store.state.fileList[i].filePath);
        }
        this.$store.commit("setFileList", []);
      }
    },
    //删除图片集合
    delEditImgs(index) {
      this.editForm.imgList.splice(index, 1);
    }
  }
};
</script>
<style lang='scss'>
.theme_selected_card {
  display: inline-block;
  border: 1px solid #ebeef5;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  overflow: hidden;
}
.theme_selected_card:hover {
  box-shadow: 0 2px 12px 0 #619aec8c;
  .theme_selected_card_del {
    opacity: 1;
  }
}

.theme_selected_card_del {
  width: 50px;
  height: 30px;
  background: #f56c6c;
  transform: rotate(45deg);
  position: absolute;
  right: -17px;
  top: -7px;
  text-align: center;
  padding-top: 11px;
  box-shadow: 0 1px 1px #ccc;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}
.card_bottom_menu {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  font-size: 16px;
}
.theme_img_card {
  width: 100px;
  height: 100px;
  background: rgba(235, 235, 235, 0.5);
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
  position: relative;
  overflow: hidden;
}
.delImgIcon {
  width: 50px;
  height: 30px;
  background: #f56c6c;
  transform: rotate(45deg);
  position: absolute;
  right: -20px;
  top: -8px;
  text-align: center;
  padding-top: 5px;
  box-shadow: 0 1px 1px #ccc;
  cursor: pointer;
}
.theme_addImg_card {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  .theme_addImg_card_icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
}
.theme_addImg_card:hover {
  border-color: #5e72f9;
}
</style>